:root {
  --color-corporativo: #0a7058 !important;
  --color-secundario: #6564DB !important;
  --color-resalto: #92D7C2 !important;
}

.lds-grid div {
  background: #0a7058 !important;
}

.ticket-nuevo .btn-aceptar {
  color: #0a7058 !important;
}

.sin-autor {
  background: #0a7058 !important;
  color: white !important;
}

.sin-autor .icon-black {
  color: white !important;
}

.autor {
  background: #6564DB !important;
  color: white !important;
}

.autor .icon-black {
  color: white !important;
}

.pagotarjeta iframe {
  height: 20px !important;
}

.img-formapago {
  margin-left: 0px !important;
  margin-right: 6px !important;
}

.btn-aceptar {
  color: var(--color-corporativo) !important;
}

.ionsmenuclass {
  color: var(--color-secundario) !important;
}



.Logotipo {
  padding-bottom: 30px;
  display: block;
  width: 67%;
  height: auto;
  max-width: 400px;
  margin: auto;
  padding-top: 48px;
}

.LogotipoHome {
  display: block;
  width: 63%;
  height: auto;
  margin: auto;
  margin-top: 15%;
  margin-bottom: 8%;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid var(--color-corporativo) !important;
  outline: none;
}

.icono-linea-sms:focus,
.icono-linea-sms:active,
.icono-linea-sms:visited,
.icono-linea-sms:hover,
.icono-linea-sms:focus-visible {
  border: 1px solid var(--color-secundario) !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-llama:focus,
.icono-linea-llama:active,
.icono-linea-llama:visited,
.icono-linea-llama:hover,
.icono-linea-llama:focus-visible {
  border: 1px solid var(--color-corporativo) !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-gb:focus,
.icono-linea-gb:active,
.icono-linea-gb:visited,
.icono-linea-gb:hover,
.icono-linea-gb:focus-visible {
  border: 1px solid var(--color-resalto) !important;
  border-radius: 20px;
  outline: none;
}

.select-tlf-home:focus,
.select-tlf-home:active,
.select-tlf-home:visited,
.select-tlf-home:hover,
.select-tlf-home:focus-visible {
  border: 1px solid var(--color-secundario) !important;
  outline: none;
}

.icono-linea:focus,
.icono-linea:active,
.icono-linea:visited,
.icono-linea:hover,
.icono-linea:focus-visible {
  border: 1px solid #005fa6 !important;
  border-radius: 20px;
  outline: none;
}

.num-contrato {
  color: var(--color-corporativo) !important;
}

.ul.list-unstyled li {

  border: 1px solid lightgray !important;

}

.nombre-tarifa {
  color: white !important;
}

.modal-header-tarifas .modal-title {
  color: white !important;
}

.modal-cerrar-tarifa .icon-black-tarifas {
  color: black !important;
}

.btn-verde-xl {
  color: #0a7058 !important;
}

.btn-rojo-xl-tarifas {
  color: white !important;
}

.btn-rojo-xl-tarifas .icon-black {
  color: white !important;
}




ion-fab-button {
  --background: #b7f399;
  --background-activated: #87d361;
  --background-hover: #a3e681;
  --border-radius: 15px;
  --box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
  --color: black;

}

ion-fab-button {
  width: 150px;
  font-size: medium;

}